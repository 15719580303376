<template>
    <page-content title="设备监控管理">
        <div style="display: flex">
        <a-space style="width: 300px;" direction="vertical">

            <a-button ghost block type="primary" @click="add">添加摄像头</a-button>

            <common-table
                    ref="table"
                    :path="`web/camera/device/${iotId}`"
                    :pageable="false"
                    :search="false"
                    :columns="columns">

                <template slot="search">
                    <a-button ghost type="primary" @click="add">新增</a-button>
                </template>

                <template slot="logo" slot-scope="{record}">
                    <a-avatar shape="square" :size="30" :src="record.avatarUrl">{{record.nickName}}</a-avatar>
                </template>

                <template slot="operation" slot-scope="{record}">
                    <action-view @click="view(record)"></action-view>
                    <action-edit @click="edit(record)"></action-edit>
                    <action-delete @click="remove(record)"></action-delete>
                </template>

            </common-table>
        </a-space>

        <CameraPlayer v-if="camera" ref="cameraPlayer" :camera="camera"></CameraPlayer>

        </div>

        <CameraEdit ref="cameraEdit" @success="getList"></CameraEdit>

    </page-content>
</template>
<script>
    import CameraEdit from "./CameraEdit";
    import CameraPlayer from "./CameraPlayer";

    export default {
        components: {
            CameraEdit,CameraPlayer
        },
        data() {
            return {
                iotId:this.$route.params.iotId,
                camera:null
            }
        },

        computed: {
            columns() {
                return [

                    {
                        title: '名称',
                        dataIndex: 'name'
                    },
                    {
                        title: '操作',
                        dataIndex: 'operation',
                        width:100,
                        scopedSlots: {customRender: 'operation'}
                    }
                ]
            }
        },
        methods: {
            add(){
                this.$refs.cameraEdit.show({iotId:this.iotId})
            },
            view(record) {
                // this.$router.push('/device/camera/' + record.id)
                this.camera = {}
                setTimeout(()=>{
                    this.camera = record
                },200)

            },
            edit(record){
                this.$refs.cameraEdit.show(record)
            },
            remove(record){
                let that = this
                this.$confirm({
                    title: '是否删除该监控?',
                    content: '是否删除该监控',
                    centered: true,
                    onOk() {
                        that.$delete('web/camera/' + record.id).then(() => {
                            that.$message.success('删除成功')
                            that.getList()
                        })
                    }
                })
            },
            getList() {
                this.$refs.table.getData()
            }

        }
    }
</script>
<style lang="less" scoped>

</style>
