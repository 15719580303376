<template>
    <div style="flex: 1;margin-left: 20px">
        <a-descriptions :column="2" :bordered="true" size="small" style="margin-bottom: 20px">
            <a-descriptions-item label="监控名称">{{camera.name}}</a-descriptions-item>
            <a-descriptions-item label="添加时间">{{camera.createdAt}}</a-descriptions-item>
            <!--            <a-descriptions-item label="直播地址">{{camera.link}}</a-descriptions-item>-->
        </a-descriptions>
        <d-player v-if="camera.link" class="player" :options="options" ref="player"></d-player>
    </div>
</template>
<script>

    import VueDPlayer from 'vue-dplayer'
    import 'vue-dplayer/dist/vue-dplayer.css'

    export default {
        components: {
            'd-player': VueDPlayer,
        },
        props:["camera"],
        data() {
            return {

            }
        },
        computed: {
            playerOptions() {
                return {
                    height: '800',
                    sources: [{
                        type: 'application/x-mpegURL',
                        src: this.camera.link
                        // type:'video/mp4',
                        // src:""
                    }],
                    // techOrder: ['flash'],
                    autoplay: true,
                    controls: false,
                    // poster: 'https://surmon-china.github.io/vue-quill-editor/static/images/surmon-9.jpg'
                }
            },
            options() {
                return {
                    video: {
                        url: this.camera.link,
                        type: 'flv'
                    }
                }
            }
        },

        created() {

        },
        methods: {}

    }
</script>
<style lang="less" scoped>
.player{
    border-radius: 5px;
    overflow: hidden;
}
</style>
